import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppsOptions } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { getUniquePages } from '../../../../utils/pages';
import { isApplicationInstalled } from '../../../wrappers/tpa';
import { getAllIntegratedApps, getIsADI, setIntegratedApps } from '../../../services/applicationState';
import {
  ALWAYS_REGISTERED_APPS,
  ALWAYS_REGISTERED_SOCIAL_MA_APPS,
  PROFILE_PAGE_BOB_APP_DEF_ID,
  SOCIAL_APPS_IDS,
} from '../../../constants';
import { maybeResetProfileRolesOnVerticalRegistration } from '../services/blog-writer';

export const registerMembersAreaApps = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  verticalAppDefId: string,
  applicationsOptions: MembersAreaAppsOptions,
) => {
  const applicationDefinitions = await getAppDefinitions({
    i18next: createInstance(),
    applications,
    editorSDK,
    applicationsOptions,
  });
  const currentIntegratedAppsMap = getAllIntegratedApps();
  const verticalsApps = getUniquePages([
    ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
    ...applicationDefinitions,
  ]);

  setIntegratedApps({
    ...currentIntegratedAppsMap,
    [verticalAppDefId]: verticalsApps,
  });

  await maybeResetProfileRolesOnVerticalRegistration(editorSDK, verticalAppDefId, verticalsApps);
};

const hasSocialPages = async (editorSDK: EditorSDK) => {
  for (const appDefinitionId of SOCIAL_APPS_IDS) {
    if (await isApplicationInstalled({ editorSDK, appDefinitionId })) {
      return true;
    }
  }
  return false;
};

export const registerAlwaysAvailableApps = async (editorSDK: EditorSDK) => {
  if (getIsADI()) {
    return;
  }

  const isSocialMA = await hasSocialPages(editorSDK);
  const alwaysAvailableApps = ALWAYS_REGISTERED_APPS.concat(isSocialMA ? ALWAYS_REGISTERED_SOCIAL_MA_APPS : []);
  const alwaysAvailableAppsDefinitions = await getAppDefinitions({
    applications: alwaysAvailableApps,
    editorSDK,
    i18next: createInstance(),
  });

  setIntegratedApps({
    ...getAllIntegratedApps(),
    [PROFILE_PAGE_BOB_APP_DEF_ID]: alwaysAvailableAppsDefinitions,
  });
};
