import {CashierMandatoryFieldsOverrides} from '../../../types/app.types';
import {COUNTRIES_WITH_VAT, FORMS_TEMPLATE_IDS} from '../constants';
import {getContactFormInitialState, getContactFormOverrides} from './ContactForm/contactForm.utils';
import {getAddressFormInitialState, getAddressFormOverrides} from './AddressForm/addressForm.utils';
import {getVatFormInitialState} from './VatIdForm/VatForm.utils';
import {FormValues, validate} from '@wix/form-viewer';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions';
import {MemberDetailsValidator} from '../MemberDetails/WithMemberDetailsData';
import type {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';
import {
  getAdditionalInfoFormInitialState,
  getAdditionalInfoFormOverrides,
} from './AdditionalInfoForm/AdditionalInfoForm';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';

// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyNestedForms} = require('@wix/form-viewer/dist/esm/services/apply-nested-forms');
// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyFormOverrides} = require('@wix/form-viewer/dist/esm/services/form-overrides');

export const isBillingInfoValid: MemberDetailsValidator<CashierMandatoryFieldsOverrides> = async ({
  formsById,
  addressAndContact,
  checkoutSettings,
  context: cashierMandatoryFields,
}) => {
  const contactValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.CONTACT],
      values: getContactFormInitialState({
        checkoutSettings,
        contact: addressAndContact.contact,
        country: addressAndContact.address?.country,
      }),
      overrides: getContactFormOverrides({checkoutSettings, overridePhone: cashierMandatoryFields?.phone}),
    });

  const addressValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.ADDRESS],
      values: getAddressFormInitialState(checkoutSettings, addressAndContact.address),
      overrides: getAddressFormOverrides({checkoutSettings, overrides: cashierMandatoryFields}),
    });

  const vatValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.VAT_ID],
      values: getVatFormInitialState(addressAndContact.contact),
      overrides: {},
    });

  const shouldValidateVat =
    addressAndContact.address?.country && COUNTRIES_WITH_VAT.includes(addressAndContact.address?.country);

  return validateAllForms([contactValidator, addressValidator, ...(shouldValidateVat ? [vatValidator] : [])]);
};

export const isShippingDestinationValid: MemberDetailsValidator<CustomFieldModel | undefined> = async ({
  formsById,
  addressAndContact,
  checkoutSettings,
  context: customField,
}) => {
  const contactValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.CONTACT],
      values: getContactFormInitialState({
        checkoutSettings,
        contact: addressAndContact.contact,
        country: addressAndContact.address?.country,
      }),
      overrides: getContactFormOverrides({checkoutSettings}),
    });

  const addressValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.ADDRESS],
      values: getAddressFormInitialState(checkoutSettings, addressAndContact.address),
      overrides: getAddressFormOverrides({checkoutSettings}),
    });

  const additionalInfoValidator = () =>
    validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.ADDITIONAL_INFO],
      values: getAdditionalInfoFormInitialState(customField),
      overrides: getAdditionalInfoFormOverrides({checkoutSettings}),
    });

  return validateAllForms([contactValidator, addressValidator, additionalInfoValidator]);
};

function validateFormWithOverrides({
  schema,
  values,
  overrides,
}: {
  schema: ReturnType<typeof applyRuleOverrides>;
  values: FormValues;
  overrides: FormOverrides;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const form = applyNestedForms(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    applyFormOverrides(applyRuleOverrides(schema, values), overrides)
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return validate(form, values);
}

async function validateAllForms(formValidations: (() => Promise<{errors?: any[]} | undefined>)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
