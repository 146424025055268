import React from 'react'
import {SocialShare} from '../index'
import {Subtitle} from '../../subtitle'
import ss from '../social-share.scss'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import s from './with-title.scss'
import {SocialShareWithTitleProps} from './index'

export const SocialShareWithTitle: React.FC<SocialShareWithTitleProps> = ({
  componentSettings,
  isVisible,
  t,
  page,
  mobile,
  updatedInterfaceEnabled,
}) => {
  const settings = useSettings(componentSettings)
  const shareTitleText = settings.get(settingsParams.shareTitleText)

  return isVisible ? (
    <div>
      <Subtitle
        className={mobile && updatedInterfaceEnabled ? s.subtitle : null}
        dataHook="social-share"
        text={shareTitleText}
      />
      <div className={ss.container}>
        <SocialShare t={t} page={page} />
      </div>
    </div>
  ) : null
}
