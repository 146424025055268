import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import {
  getAppManifestFunc,
  openDashboard,
  openSettings,
  ACTIONS_ID,
} from './appManifest';
import { getTranslateFunction } from './i18n';

const fileShareAppDefId = '1537b24e-29d1-6d8f-b8e1-d6860f2f70b9';

let appToken;
let sdk;
let _translate;

const getFileShareEditorApi = () => ({
  async editorReady(_editorSDK, _appToken, options) {
    appToken = _appToken;
    sdk = _editorSDK;
    const { initialAppData } = options;
    console.log('initialAppData.languageCode', initialAppData.languageCode);
    _translate = await getTranslateFunction(
      initialAppData.languageCode || 'en',
    );
    sdk.addEventListener('appActionClicked', event => {
      switch (event.detail.actionId) {
        case ACTIONS_ID.ADD_FILES_AND_FOLDERS:
          openDashboard(sdk);
          break;
        case ACTIONS_ID.CUSTOMIZE_APP:
          openSettings(sdk);
          break;
        default:
          break;
      }
    });
    const isEditorX =
      options.origin.initiator === 'EDITOR' &&
      options.origin.type === 'RESPONSIVE';
    if (options.firstInstall && isEditorX) {
      sdk.addEventListener('widgetAdded', async (event) => {
        const { detail } = event;
        const { componentRef } = detail;
        const responsiveLayout = await sdk.document.responsiveLayout.get(
          'token',
          { componentRef },
        );
        await sdk.responsiveLayout.update('token', {
          componentRef,
          responsiveLayout: {
            ...responsiveLayout,
            componentLayouts: [
              {
                ...responsiveLayout.componentLayouts[0],
                width: { type: 'percentage', value: 100 },
              },
            ],
            itemLayouts: [
              {
                ...responsiveLayout.itemLayouts[0],
                justifySelf: 'center',
              },
            ],
          },
        });
      });
    }
  },
  getAppManifest: ({ appManifestBuilder }, _editorSDK) =>
    getAppManifestFunc({ appManifestBuilder }, _editorSDK, _translate),
  handleAction(args) {
    const type = args.type;
    const payload = args.payload;
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case fileShareAppDefId: {
              return sdk.pages.data
                .getAll(appToken)
                .then(allPages => {
                  const filesPage = allPages.find(
                    page =>
                      page.tpaPageId === 'files' &&
                      page.tpaApplicationId === fileShareAppDefId,
                  );
                  return (
                    filesPage &&
                    sdk.document.pages.navigateTo(appToken, {
                      pageLink: { type: 'PageLink', pageId: filesPage.id },
                    })
                  );
                })
                .then(() => sdk.document.save());
            }
            default:
              return Promise.resolve();
          }
        default:
          // console.log(type, payload);
          return Promise.resolve();
      }
    } catch (e) {
      Promise.reject();
      throw e;
    }
  },
});

const editorApp = withMembersArea(getFileShareEditorApi(), {
  membersAreaApps: [
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.FILE_SHARE,
  ],
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const getAppManifest = editorApp.getAppManifest;
