import {ItemMediaModel} from './ItemMedia.model';
import {ItemPricesModel} from './ItemPrices.model';
import {ItemTypeModel} from './ItemType.model';
import {LineItemOptionModel} from './LineItemOption.model';
import {SubscriptionModel} from './Subscription.model';
import {
  EcomCheckoutV1ItemAvailabilityStatus,
  EnrichedLineItemFragment,
  ItemAvailabilityInfoFragment,
  LineItemFragment,
  RenderingConfigFragment,
} from '../../gql/graphql';
import {LineItemWithEnrichment} from '../../types/app.types';
import {isLineItemWithEnrichment} from '../utils/types.util';

export class LineItemModel {
  public id: string;
  public catalogAppId: string;
  public productName: string;
  public media?: ItemMediaModel;
  public prices: ItemPricesModel;
  public options: LineItemOptionModel[];
  public quantity: number;
  public subscription?: SubscriptionModel;
  public itemType: ItemTypeModel;
  public renderingConfig?: RenderingConfigFragment;
  public sku?: string;

  constructor(params: LineItemFragment | LineItemWithEnrichment) {
    const {
      id,
      media,
      productName,
      lineItemPrice,
      price,
      fullPrice,
      priceBeforeDiscounts,
      catalogReference,
      quantity,
      subscriptionOptionInfo,
      itemType,
      availability,
      physicalProperties,
      descriptionLines,
    } = params;
    this.id = id!;
    this.catalogAppId = catalogReference!.appId!;
    this.itemType = new ItemTypeModel(itemType!);
    this.productName = productName?.translated ?? productName?.original ?? '';
    this.media = media && (media.id || media.url) ? new ItemMediaModel(media, this.productName) : undefined;
    this.prices = new ItemPricesModel({
      lineItemPrice: lineItemPrice ?? /* istanbul ignore next */ undefined,
      price: price ?? /* istanbul ignore next */ undefined,
      fullPrice: fullPrice ?? /* istanbul ignore next */ undefined,
      priceBeforeDiscounts: priceBeforeDiscounts ?? /* istanbul ignore next */ undefined,
    });
    this.options = descriptionLines
      ? LineItemOptionModel.convertToOptionsList(descriptionLines)
      : /* istanbul ignore next */ [];
    this.quantity = calculateQuantity(availability!, Number(quantity));
    this.subscription = subscriptionOptionInfo ? new SubscriptionModel(subscriptionOptionInfo) : undefined;
    this.sku = physicalProperties?.sku ?? undefined;
    this.renderingConfig = isLineItemWithEnrichment(params)
      ? params.renderingConfig ?? /* istanbul ignore next */ undefined
      : undefined;
  }
}

const calculateQuantity = (availability: ItemAvailabilityInfoFragment, quantity: number): number => {
  if (availability.status === EcomCheckoutV1ItemAvailabilityStatus.NOT_AVAILABLE) {
    return 0;
  }
  if (availability.status === EcomCheckoutV1ItemAvailabilityStatus.PARTIALLY_AVAILABLE) {
    return availability.quantityAvailable!;
  }
  return quantity;
};

export function getItemEnricher(
  enrichedItems?: (EnrichedLineItemFragment | null)[] | null
): (item: LineItemFragment | null) => LineItemModel {
  return function enrichItem(item: LineItemFragment | null) {
    const enrichedItem = (enrichedItems ?? []).find((enrichedItem) => enrichedItem?.id === item?.id);
    const overriddenDescriptionLines = enrichedItem?.overriddenDescriptionLines?.descriptionLines;
    const mergedItem = {
      ...item,
      ...enrichedItem,
      ...(overriddenDescriptionLines ? {descriptionLines: overriddenDescriptionLines} : {}),
    };
    return new LineItemModel(mergedItem);
  };
}
