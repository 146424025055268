import {Step} from '../../StepsManager/Components/Step';
import React from 'react';
import {PlaceOrderOpen} from './Components/PlaceOrderOpen';
import {PlaceOrderEmpty} from './Components/PlaceOrderEmpty';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps} from '../../../../../types/app.types';

export enum PlaceOrderDataHook {
  root = 'PlaceOrder.root',
  subtitle = 'PlaceOrder.subtitle',
  collapsed = 'PlaceOrder.collapsed',
  open = 'PlaceOrder.open',
  empty = 'PlaceOrder.empty',
  placeOrderButton = 'PlaceOrder.placeOrderButton',
}

export const PlaceOrderStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      title={localeKeys.checkout.place_order.$value()}
      dataHook={PlaceOrderDataHook.root}
      openComponent={PlaceOrderOpen}
      emptyComponent={PlaceOrderEmpty}
    />
  );
};
