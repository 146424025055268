import {
  chain,
  curry,
  filter,
  find,
  includes,
  size,
  trimStart,
  values,
} from 'lodash';
import { createSelector } from 'reselect';
import { ENTITY_TYPE_POSTS } from '@wix/communities-blog-client-common';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import {
  sortCategoryPosts,
  sortDrafts,
  sortFeedPosts,
  sortProfilePosts,
} from '@wix/communities-blog-universal/dist/src/utils/sort-posts';
import { getLocalizedYearAndMonth } from '../helpers/date';
import { secondParam } from '../services/param-selectors';
import { isValidAdvancedSlug } from '../services/slug';
import { getTimezone } from '../store/basic-params/basic-params-selectors';
import { AppState, NormalizedPost } from '../types';
import {
  getCurrentPageEntities,
  getEntitiesByPage,
} from './pagination-selectors';

export const getPostMap = (state: { posts: Record<string, NormalizedPost> }) =>
  state.posts ?? {};

export const getPostCount = createSelector(getPostMap, (posts) => size(posts));

export const getPost = createSelector(
  [getPostMap, (_, id: string) => id],
  (posts, postId) => posts[postId],
);

export const getPostBySlug = createSelector(
  [getPostMap, (_, slug: string) => slug],
  (posts, slug) => {
    if (typeof slug !== 'string') {
      return undefined;
    }

    const lowerCaseSlug = slug.toLowerCase();
    const [year, month, postSlug] = trimStart(lowerCaseSlug, '/').split('/');
    if (isValidAdvancedSlug({ year, month, postSlug })) {
      return find(
        posts,
        (post) =>
          includes(post.slugs, lowerCaseSlug) ||
          includes(post.slugs, `${lowerCaseSlug}/`),
      );
    }

    return find(posts, (post) => includes(post.slugs, lowerCaseSlug));
  },
);

export const getPostByIdOrSlug = (state: AppState, idOrSlug: string) =>
  getPost(state, idOrSlug) || getPostBySlug(state, idOrSlug);

export const getPostSlugById = (state: AppState, id: string) =>
  getPost(state, id)?.slug;

const getEntityIdsByPage = (state: AppState, page: number) =>
  getEntitiesByPage(state, ENTITY_TYPE_POSTS)?.[page] ?? [];

const getEntityIdsByIdAndPage = (state: AppState, id: string, page: number) =>
  getEntityIdsByPage(state, page);

const getSiteMemberId = (state: AppState, siteMemberId: string) => siteMemberId;

const isPublished = (post: NormalizedPost | undefined) =>
  post ? post.status === POST_STATUS.published : false;

export const isScheduled = (post: NormalizedPost | undefined) =>
  post ? post.status === POST_STATUS.scheduled : false;

export const getPostScheduleDate = (post: NormalizedPost | undefined) =>
  post ? post.scheduledPublishDate : false;

export const isUnpublished = (post: NormalizedPost | undefined) =>
  post ? post.status === POST_STATUS.unpublished : false;

export const isInModeration = (post: NormalizedPost | undefined) => {
  const moderationStatuses: string[] = [
    POST_STATUS.in_review,
    POST_STATUS.in_moderation,
  ];

  return post ? moderationStatuses.includes(post.status) : false;
};

const hasCategoryId = (categoryId: string, post: NormalizedPost | undefined) =>
  post ? includes(post.categoryIds, categoryId) : false;

const hasTagId = curry(
  (tagId: string | undefined, post: NormalizedPost | undefined) =>
    post ? includes(post.tagIds, tagId) : false,
);

const createdInYearAndMonth =
  (year: string, month: string, timeZone: string) => (post: NormalizedPost) => {
    const firstPublishedDate = getLocalizedYearAndMonth(
      post.firstPublishedDate,
      timeZone,
    );
    return (
      firstPublishedDate.year === parseInt(year, 10) &&
      firstPublishedDate.month === parseInt(month, 10)
    );
  };

export const getSortedPostsByCategoryId = createSelector(
  [getPostMap, secondParam],
  (posts, mixedCategoryId) =>
    sortCategoryPosts(
      chain(posts)
        .filter((post) => hasCategoryId(mixedCategoryId, post))
        .value(),
    ),
);

export const getPostsByCategoryIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, mixedCategoryId, entityIds) =>
    chain(entityIds)
      .map((id) => posts[id])
      .filter((post) => hasCategoryId(mixedCategoryId, post))
      .value(),
);

export const getSortedCategoryPosts = createSelector([getPostMap], (posts) =>
  sortCategoryPosts(Object.values(posts)),
);

export const getPostsByPage = createSelector(
  [getPostMap, getEntityIdsByPage],
  (posts, entityIds) => entityIds.map((id) => posts[id]),
);

export const getSortedPostsByArchiveDate = createSelector(
  [getPostMap, (_, date: { year: string; month: string }) => date, getTimezone],
  (posts, { year, month }, timeZone) =>
    sortCategoryPosts(
      chain(posts).filter(createdInYearAndMonth(year, month, timeZone)).value(),
    ),
);

export const getPostsByArchiveDateAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage, getTimezone],
  (posts, { year, month }, entityIds, timeZone) =>
    chain(entityIds)
      .map((id) => posts[id])
      .filter(createdInYearAndMonth(year, month, timeZone))
      .value(),
);

export const getSortedPostsByTagId = createSelector(
  [getPostMap, (_, tagId: string | undefined) => tagId],
  (posts, tagId) =>
    sortCategoryPosts(
      chain(posts).filter(hasTagId(tagId)).filter(isPublished).value(),
    ),
);

export const getPostsByHashtag = createSelector(
  [getPostMap, (_, hashtag: string) => hashtag],
  (posts, hashtag) =>
    chain(posts)
      .filter(
        (p) =>
          p.hashtags?.some(
            (postHashtag) =>
              postHashtag.toLowerCase() === hashtag.toLowerCase(),
          ) ?? false,
      )
      .value(),
);

export const getPostsByTagIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, tagId, entityIds) =>
    chain(entityIds)
      .map((id) => posts[id])
      .filter(hasTagId(tagId))
      .value(),
);

export const getFeedPosts = createSelector([getPostMap], (postsById) =>
  sortFeedPosts(Object.values(postsById)),
);

export const getMyPosts = createSelector([getPostMap], (posts) =>
  sortProfilePosts(Object.values(posts)),
);

export const getPostListPosts = createSelector([getPostMap], (posts) =>
  values(posts),
);

export const getRelatedPosts = createSelector([getPostMap], (posts) =>
  values(posts),
);

export const getFeedPostsByPage = createSelector(
  [getPostMap, getEntityIdsByPage],
  (posts, entityIds) => entityIds.map((id) => posts[id]),
);

const hasSiteMemberId = curry(
  (siteMemberId: string, post: NormalizedPost) =>
    post?.owner?.siteMemberId === siteMemberId,
);

export const getSortedPostsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) =>
    sortProfilePosts(
      chain(posts)
        .filter(hasSiteMemberId(siteMemberId))
        .filter(isPublished)
        .value(),
    ),
);

export const getSortedDraftsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) =>
    sortDrafts(
      chain(posts)
        .filter(hasSiteMemberId(siteMemberId))
        .filter(isUnpublished)
        .value(),
    ),
);

export const getPublishedPosts = createSelector([getPostMap], (posts) =>
  filter(posts, isPublished),
);
export const getDrafts = createSelector([getPostMap], (posts) =>
  filter(posts, isUnpublished),
);

export const getPublishedPostCount = createSelector([getPostMap], (posts) =>
  chain(posts).filter(isPublished).size().value(),
);

export const getDraftCount = createSelector([getPostMap], (posts) =>
  chain(posts).filter(isUnpublished).size().value(),
);

export const getPostsForPage = createSelector(
  [getPostMap, getCurrentPageEntities],
  (posts, entityIds) =>
    chain(entityIds)
      .map((id) => posts?.[id])
      .compact()
      .value(),
);

export const getLastPublishedDate = (post: NormalizedPost) =>
  post.lastPublishedDate;

export const getPostCoverImage = (post: NormalizedPost) => post.coverImage;

export const getPostCoverImageSrc = (post: NormalizedPost) =>
  getPostCoverImage(post)?.src;
