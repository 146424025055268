import React, { useEffect, useRef, useState } from 'react';
import { GALLERY_CONSTS, ProGallery } from 'pro-gallery';

import classes from './Gallery.scss';
import Section from '../Section';
import {
  GalleryArrowPositionOptions,
  GalleryItemSizeOptions,
  GalleryLayoutOptions,
  ImageResizeOptions,
  SectionTypes,
} from '../../types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useDimensions } from '../useDimensions';
import settingsParams from '../../settingsParams';
import stylesParams from '../../stylesParams';
import imageSDK from '@wix/image-client-api/dist/imageClientSDK';
import { GallerySectionViewModel } from '../../../../service-page-view-model/gallery-section-view-model/gallerySectionViewModel';
import type { GalleryProps } from 'pro-gallery-lib';
import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';

type GalleryComponentProps = {
  viewModel: GallerySectionViewModel;
};

const Gallery: React.FC<GalleryComponentProps> = ({ viewModel }) => {
  const widgetDimensions = useDimensions();
  const { isMobile, isEditor, isPreview, isEditorX, isViewer } =
    useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const styles = useStyles();
  const isSSRProGalleryEnabled = experiments.enabled(
    'specs.bookings.SSRProGallery',
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = isSSRProGalleryEnabled
    ? undefined
    : containerRef.current?.getClientRects()?.[0];
  const selectedLayout: GalleryLayoutOptions = settings.get(
    settingsParams.galleryLayout,
  );

  const [galleryHeight, setGalleryHeight] = useState(0);
  const isSliderImageResizeOptionCrop =
    settings.get(settingsParams.gallerySliderImageResizeOption) ===
    ImageResizeOptions.CROP;

  const getGalleryDimensions = () => {
    if (!containerDimensions || !widgetDimensions) {
      return;
    }

    return {
      height:
        selectedLayout === GalleryLayoutOptions.SLIDER
          ? settings.get(settingsParams.gallerySliderHeight)
          : containerDimensions.width,
      width: containerDimensions.width,
    };
  };

  const galleryItemSizeOptionsToPixels = (
    galleryItemSizeOptions: GalleryItemSizeOptions,
  ) => {
    if (isMobile) {
      return 200;
    }
    switch (galleryItemSizeOptions) {
      case GalleryItemSizeOptions.LARGE:
        return 500;
      case GalleryItemSizeOptions.SMALL:
        return 150;
      case GalleryItemSizeOptions.MEDIUM:
      default:
        return 300;
    }
  };

  const getOptionsByGalleryLayoutOptions = () => {
    const isShowArrows =
      viewModel.items.length > 1 &&
      settings.get(settingsParams.gallerySliderShowArrows);
    switch (selectedLayout) {
      case GalleryLayoutOptions.MASONRY:
        return {
          galleryLayout: GALLERY_CONSTS.layout.MASONRY,
          itemBorderColor: styles.get(
            stylesParams.galleryMasonryItemBorderColor,
          ).value,
          itemBorderWidth: settings.get(
            settingsParams.galleryMasonryItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.galleryMasonryItemRadius,
          ),
          imageMargin: settings.get(settingsParams.galleryMasonryItemSpacing),
          gallerySizeType: 'px',
          gallerySizePx: galleryItemSizeOptionsToPixels(
            settings.get(settingsParams.galleryMasonryItemSize),
          ),
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
        };
      case GalleryLayoutOptions.GRID:
        return {
          galleryLayout: GALLERY_CONSTS.layout.GRID,
          itemBorderColor: styles.get(stylesParams.galleryGridItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.galleryGridItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.galleryGridItemBorderWidth,
          ),
          itemBorderRadius: settings.get(settingsParams.galleryGridItemRadius),
          gallerySizeType: 'px',
          gallerySizePx: settings.get(settingsParams.galleryGridThumbnailSize),
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
        };
      case GalleryLayoutOptions.SLIDER:
      default:
        return {
          galleryLayout: GALLERY_CONSTS.layout.SLIDER,
          itemBorderColor: styles.get(stylesParams.gallerySliderItemBorderColor)
            .value,
          imageMargin: settings.get(settingsParams.gallerySliderItemSpacing),
          itemBorderWidth: settings.get(
            settingsParams.gallerySliderItemBorderWidth,
          ),
          itemBorderRadius: settings.get(
            settingsParams.gallerySliderItemRadius,
          ),
          cubeType: GALLERY_CONSTS.cubeType.CROP,
          slideshowLoop: settings.get(settingsParams.gallerySliderLoopImages),
          isAutoSlideshow:
            !isEditor && settings.get(settingsParams.gallerySliderAutoSlide),
          autoSlideshowInterval: settings.get(
            settingsParams.gallerySliderTimeBetweenImages,
          ),
          autoSlideshowType: 'interval',
          showArrows: isShowArrows,
          arrowsSize: settings.get(settingsParams.gallerySliderArrowsSize),
          arrowsPosition:
            settings.get(settingsParams.gallerySliderArrowsPosition) ===
              GalleryArrowPositionOptions.INSIDE || !isShowArrows
              ? GALLERY_CONSTS.arrowsPosition.ON_GALLERY
              : GALLERY_CONSTS.arrowsPosition.OUTSIDE_GALLERY,
          hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.NEVER_SHOW,
          cubeRatio: isSliderImageResizeOptionCrop ? '1' : '100%/100%',
        };
    }
  };

  const getViewMode = () => {
    if (isViewer) {
      return GALLERY_CONSTS.viewMode.SITE;
    }
    if (isPreview) {
      return GALLERY_CONSTS.viewMode.PREVIEW;
    }
    if (isEditorX || isEditor) {
      return GALLERY_CONSTS.viewMode.EDIT;
    }
  };

  const items = viewModel.items.map(
    ({ height, width, relativeUri, altText }, i) => ({
      itemId: `sample-id-${i}`,
      mediaUrl: relativeUri,
      metaData: {
        alt: altText,
        type: 'image',
        height,
        width,
      },
    }),
  );
  if (!items.length) {
    return null;
  } else {
    const viewMode = getViewMode();
    const options = getOptionsByGalleryLayoutOptions();
    const container = isSSRProGalleryEnabled
      ? {
          height: Number(
            selectedLayout === GalleryLayoutOptions.SLIDER &&
              settings.get(settingsParams.gallerySliderHeight),
          ),
          width: settings.get(settingsParams.sidebarVisibility)
            ? isMobile
              ? 280
              : 0
            : 720,
        }
      : getGalleryDimensions();
    return (
      <Section sectionType={SectionTypes.GALLERY}>
        {isSSRProGalleryEnabled ? (
          <GalleryWrapper
            items={items}
            viewMode={viewMode as Props['viewMode']}
            options={options as any}
            container={container!}
          />
        ) : (
          <div
            className={classes.root}
            style={{ height: galleryHeight }}
            ref={containerRef}
          >
            <div
              data-hook="gallery-section"
              className={classes.galleryWrapper}
              style={{ height: galleryHeight }}
            >
              <SafeGallery
                items={items}
                options={options}
                container={container}
                viewMode={viewMode}
                onGalleryDimensionsChanged={({ height }) => {
                  setGalleryHeight(height);
                }}
              />
            </div>
          </div>
        )}
      </Section>
    );
  }
};
const GALLERY_ID = 'service-page-gallery-section';
const createMediaUrl = ({
  item,
  originalUrl,
  requiredWidth,
  requiredHeight,
}): string => {
  // relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options
  return imageSDK.getScaleToFillImageURL(
    originalUrl,
    item.metaData.width,
    item.metaData.height,
    requiredWidth,
    requiredHeight,
  );
};

type Props = Pick<GalleryProps, 'items' | 'options' | 'container' | 'viewMode'>;
export const GalleryWrapper: React.FC<Props> = ({
  items,
  container: defaultContainer,
  viewMode,
  options,
}) => {
  const [container, setContainer] = useState(defaultContainer);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainer({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });
    }
  }, [containerRef.current]);
  const isPrerenderMode = !container.width;
  return (
    <div
      className={classes.wixGalleryWrapper}
      ref={containerRef}
      data-hook="gallery-section"
    >
      <ProGallery
        id={GALLERY_ID}
        viewMode={viewMode}
        items={items}
        options={options}
        isPrerenderMode={isPrerenderMode}
        container={container}
        createMediaUrl={createMediaUrl}
        totalItemsCount={items.length}
      />
      {isPrerenderMode && (
        <LayoutFixer
          id={GALLERY_ID}
          items={items}
          options={options}
          isPrerenderMode={isPrerenderMode}
        />
      )}
    </div>
  );
};
GalleryWrapper.displayName = 'GalleryWrapper';

class SafeGallery extends React.Component<
  {
    items;
    options;
    container;
    onGalleryDimensionsChanged;
    viewMode;
  },
  { hasError }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentWillReceiveProps() {
    this.setState({ hasError: false });
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    const { items, options, container, onGalleryDimensionsChanged, viewMode } =
      this.props;

    return (
      <ProGallery
        id={GALLERY_ID}
        key={JSON.stringify({ ...options, ...container })}
        items={items}
        totalItemsCount={items.length}
        viewMode={viewMode}
        options={options}
        container={container}
        eventsListener={(eName, eData) => {
          if (eName === GALLERY_CONSTS.events.GALLERY_CHANGE) {
            onGalleryDimensionsChanged({
              height: eData.layoutHeight,
              width: 0,
            });
          }
        }}
        createMediaUrl={createMediaUrl}
      />
    );
  }
}

export default Gallery;
