import React, {useEffect} from 'react';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {classes} from '../BillingDetails/BillingDetails.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {AddressForm} from '../../../../Form/AddressForm/AddressForm';
import {usePaymentsApi} from '../../../../WithPaymentsApi/WithPaymentsApi';
import {updateMandatoryFields} from '../../../../../../domain/utils/cashier.utils';
import {getCountryFromAddressFormValues} from '../../../../Form/AddressForm/addressForm.utils';
import {VatForm} from '../../../../Form/VatIdForm/VatForm';
import {COUNTRIES_WITH_VAT, FIELDS_GAP} from '../../../../constants';

export enum BillingDetailsFormDataHook {
  root = 'BillingDetailsForm.root',
  contactForm = 'BillingDetails.contactForm',
  addressForm = 'BillingDetailsForm.addressForm',
  vatForm = 'BillingDetailsForm.vatForm',
}

export const BillingDetailsForm = () => {
  const {
    formsStore: {areFormsLoaded},
    checkoutStore: {isShippingFlow},
    paymentStore: {cashierMandatoryFields, setCashierMandatoryFields},
  } = useControllerProps();

  const {contactFormData, addressFormData, vatFormData, updateContactCountry, billingSameAsShipping} = useBillingData();

  const {paymentsApi} = usePaymentsApi();

  const country = getCountryFromAddressFormValues(addressFormData.formValues);
  const shouldShowBillingForm = isShippingFlow ? !billingSameAsShipping : true;

  useEffect(
    () => {
      void updateContactCountry(country);
      void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  return (
    <>
      {!areFormsLoaded && /* istanbul ignore next */ <FormLoader />}
      {areFormsLoaded && shouldShowBillingForm && (
        <div
          className={classes.forms}
          style={{
            gap: `${FIELDS_GAP}px`,
          }}>
          <div data-hook={BillingDetailsFormDataHook.contactForm}>
            <ContactForm {...contactFormData} overridePhone={cashierMandatoryFields.phone} />
          </div>
          {country && COUNTRIES_WITH_VAT.includes(country) && (
            <div data-hook={BillingDetailsFormDataHook.vatForm}>
              <VatForm {...vatFormData} />
            </div>
          )}
          <div data-hook={BillingDetailsFormDataHook.addressForm}>
            <AddressForm {...addressFormData} overrides={cashierMandatoryFields} />
          </div>
        </div>
      )}
    </>
  );
};
