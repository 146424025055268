import { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { MembersAreaAppId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { BiData } from '../../../types/bi';
import { getTranslationFunction } from '../../../i18n';
import { log, toMonitored } from '../../../utils/monitoring';
import { shouldDisableParallelAppInstall, shouldUseNewAppInstallCheck } from '../../../utils/experiments';
import { withTimeoutMonitor } from '../../../utils/promise-timeout';
import enforceSequentiality from '../../enforceSequentiality';
import * as applicationState from '../../applicationState';
import { runAndWaitForApproval } from '../../wrappers/transactions';
import * as routersWrapper from '../../wrappers/routers';
import * as pagesWrapper from '../../wrappers/pages';
import { openGeneralSettingsPanel } from '../../wrappers/panels';
import * as appState from '../../services/applicationState';
import { refreshMembersAreaApps } from '../../services/members-area';
import * as pagesService from '../../services/pages';
import { getProfileType, setProfileType } from '../../services/profile';
import * as routersService from '../../services/routers';
import * as membersIntegrationApi from '../../services/integration';
import { getMemberPrivacySettings } from '../../services/members-privacy-settings';
import * as generalSettings from '../../services/general-settings';
import { addApplications, createConnectionConfigs } from '../../platform-api/addApplications';
import { removeMembersAreaPageByPageId } from '../../platform-api/removeMembersAreaPage';
import { setHorizontalLayout, setHorizontalPWHeight, setSidebarLayout } from '../../platform-api/layouts';
import { MembersAreaPublicApi } from '../types';

const ADD_APPS_TIMEOUT_AMOUNT = 15000;

export const maybeAddApplications = async (
  applications: IntegrationApplication[] | MembersAreaAppId[],
  shouldNavigate: boolean,
  options?: { biData: BiData },
) => {
  const editorSDK = appState.getEditorSDK();
  if (!editorSDK) {
    return;
  }

  const isReady = await applicationState.isApplicationReady(editorSDK, { shouldLog: false });
  if (!isReady) {
    console.warn('Members Area installation was corrupted so the integrations pages will not be added');
    log('Skipping addApplications as the application is not ready and probably already deleted');
    return;
  }
  const applicationDefinitions = await getAppDefinitions({ applications, editorSDK, i18next: createInstance() });

  return addApplications({ editorSDK, applications: applicationDefinitions, shouldNavigate, biData: options?.biData });
};

const getUniqueNewPublicPageUriSeo = async (editorSDK: EditorSDK, initialPrefix = 'blank') => {
  let pageUriSeo;

  const routers = await routersWrapper.getAll(editorSDK);
  const pages = await pagesWrapper.getAllPages({ editorSDK });
  const routerPageUris = Object.keys(
    routers.find((router) => router.config.type === 'public')?.config.patterns || {},
  ).map((pattern) => pattern.split('/{userName}/')[1]);
  const pagesSEOUris = pages.map(({ pageUriSEO }) => pageUriSEO);

  let counter = 1;
  let isUnique;

  do {
    pageUriSeo = `${initialPrefix}-${counter}`;
    isUnique = !routerPageUris.includes(pageUriSeo) && !pagesSEOUris.includes(pageUriSeo);
    counter++;
  } while (!isUnique && counter < 1000);

  return pageUriSeo;
};

const createBlankPage = async (editorSDK: EditorSDK, isPrivate = true) => {
  let pageRef;

  try {
    const t = await getTranslationFunction(editorSDK);
    const pageUriSEO = isPrivate ? undefined : await getUniqueNewPublicPageUriSeo(editorSDK);
    const pageTitle = isPrivate ? t('Pages_New_Private_Page_Title') : t('Pages_New_Public_Page_Title');
    pageRef = await pagesWrapper.addPage({ editorSDK, pageTitle, pageUriSEO });
    if (!isPrivate) {
      await pagesWrapper.updatePageData({
        editorSDK,
        pageRef,
        pageData: { pageSecurity: { requireLogin: false }, hidePage: false },
      });
    }

    const createdPage: PageData & { pageRef?: PageRef } = await pagesWrapper.getPageData({ editorSDK, pageRef });
    const routers = (await routersService.getMembersAreaRouters(editorSDK)) || { publicRouter: {}, privateRouter: {} };
    createdPage.pageRef = pageRef;
    const apps = [
      {
        appDefinitionId: createdPage.managingAppDefId as string,
        pageId: '',
        social: !isPrivate,
        showInLoginMenu: true,
        showInMemberMenu: true,
      },
    ];

    const connectionConfigs = createConnectionConfigs({ applications: apps, pages: [createdPage], routers });
    await pagesService.connectPagesToMembers({ editorSDK, pages: connectionConfigs });
    await pagesService.setStateForPages(editorSDK);
  } catch (e) {
    log('Add custom page failed', {
      tags: { reason: (e as Error).toString() + '\n' + (e as Error).stack, isPrivate, pageRefAdded: !!pageRef },
    });
  }
};

const refreshPageState = (editorSDK: EditorSDK) => pagesService.setStateForPages(editorSDK);

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const transaction =
    <T extends any[], G>(action: (...args: T) => G) =>
    (...props: T) =>
      runAndWaitForApproval<G>(editorSDK, () => action(...props));

  const sequentialMonitoredTransaction = <T>(name: string, action: () => Promise<T>) => {
    return enforceSequentiality(name, () => toMonitored(name, transaction(action))) as unknown as Promise<T>;
  };

  return {
    addApplications: async (applications, shouldNavigate, options) => {
      const interactionName = (await shouldDisableParallelAppInstall())
        ? 'editorApi.addApplicationsSync'
        : 'editorApi.addApplications';
      const monitoredPromise = () =>
        toMonitored('addApplications', () =>
          sequentialMonitoredTransaction(interactionName, () =>
            maybeAddApplications(applications, shouldNavigate, options),
          ),
        );
      return withTimeoutMonitor('addApplications', ADD_APPS_TIMEOUT_AMOUNT, monitoredPromise);
    },
    getMembersPageRef: (page) => {
      if (!page) {
        return Promise.resolve(undefined);
      }
      const { appDefinitionId, appPageId } = page;
      return routersWrapper.findPageRefByAppData(editorSDK, appDefinitionId, appPageId);
    },
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      return sequentialMonitoredTransaction('editorApi.removeMembersAreaPage', () =>
        removeMembersAreaPageByPageId({ pageId, appDefinitionId, editorSDK }),
      );
    },
    setHorizontalLayout: (pwHeight) => {
      return sequentialMonitoredTransaction('editorApi.setHorizontalLayout', () =>
        setHorizontalLayout(editorSDK, pwHeight),
      );
    },
    setSidebarLayout: () => {
      return sequentialMonitoredTransaction('editorApi.setSidebarLayout', () => setSidebarLayout(editorSDK));
    },
    _getIsResponsiveEditor: () => {
      return enforceSequentiality('_getIsResponsiveEditor', async () => appState.getIsResponsiveEditor());
    },
    handleVerticalDeletion: async (verticalAppDefId) => {
      const useNewAppInstallCheck = await shouldUseNewAppInstallCheck();
      const interactionName = useNewAppInstallCheck
        ? 'editorApi.handleVerticalDeletion.newIsAppInstalled'
        : 'editorApi.handleVerticalDeletion';
      return sequentialMonitoredTransaction(interactionName, () =>
        membersIntegrationApi.handleVerticalDeletion(verticalAppDefId, editorSDK),
      );
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      return membersIntegrationApi.registerMembersAreaApps(
        applications,
        verticalAppDefId,
        editorSDK,
        applicationsOptions,
      );
    },
    installRegisteredApps: async (verticalAppDefId: string, options: { biData?: { origin?: string } }) => {
      const useNewAppInstallCheck = await shouldUseNewAppInstallCheck();
      const interactionName = useNewAppInstallCheck
        ? 'editorApi.installRegisteredApps.newIsAppInstalled'
        : 'editorApi.installRegisteredApps';
      return sequentialMonitoredTransaction(interactionName, () =>
        membersIntegrationApi.installRegisteredApps(verticalAppDefId, editorSDK, options),
      );
    },
    getRegisteredApps: () => {
      return membersIntegrationApi.getRegisteredApps(editorSDK);
    },
    addCustomPage: (isPrivate) => {
      return sequentialMonitoredTransaction('editorApi.createBlankPage', () => createBlankPage(editorSDK, isPrivate));
    },
    refreshPageState: () => refreshPageState(editorSDK),
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (type) => {
      return sequentialMonitoredTransaction('editorApi.setProfileType', () => setProfileType(editorSDK, type));
    },
    refreshRouters: () => routersWrapper.refreshRouters(editorSDK),
    refreshMembersAreaApps: () => refreshMembersAreaApps(editorSDK),
    setProfileWidgetHeight: (height) => setHorizontalPWHeight(editorSDK, height),
    openGeneralSettingsPanel: (referralInfo) => {
      return openGeneralSettingsPanel({ editorSDK, eventPayload: { pageRef: null, referralInfo } });
    },
    getGeneralSettingsPanelData: () => {
      return generalSettings.getGeneralSettingsPanelData(editorSDK);
    },
    getMemberPrivacySettings: () => getMemberPrivacySettings(editorSDK),
    modifyPages: (configurationDetails) => {
      return sequentialMonitoredTransaction('editorApi.modifyPages', () =>
        generalSettings.modifyPages(editorSDK, configurationDetails),
      );
    },
    modifyPagesAndOpenManagePages: (configurationDetails, referralInfo) => {
      return sequentialMonitoredTransaction('editorApi.modifyPagesAndOpenManagePages', () =>
        generalSettings.modifyPagesAndOpenManagePages(editorSDK, configurationDetails, referralInfo),
      );
    },
    registerAdditionalWidgets: () => {},
    getAdditionalWidgets: () => {},
    installAdditionalWidgets: () => {},
    openAddTabsPanel: () => Promise.resolve(),
    getIsMembersAreaV2Context: () => Promise.resolve(false),
  };
};
