import { removeDashes } from '@wix/wix-vod-shared/common';
import type { ISettingsFont } from '@wix/tpa-settings';

function normalizeIds(channelId: string, videoId: string, siteOwnerId: string) {
  return {
    // if videoId is present the channelId should be UID
    channelId: videoId ? siteOwnerId : removeDashes(channelId),
    videoId: removeDashes(videoId),
  };
}

export function normalizeIdsFromSettings(
  channelId: string,
  videoId: string,
  siteOwnerId: string,
) {
  return normalizeIds(channelId, videoId, siteOwnerId);
}

export function normalizeIdsFromFonts(
  channelIdFont: ISettingsFont,
  videoIdFont: ISettingsFont,
  siteOwnerId: string,
) {
  let channelId = '';
  let videoId = '';

  if (typeof channelIdFont === 'string') {
    channelId = channelIdFont;
  }

  if (typeof videoIdFont === 'string') {
    videoId = videoIdFont;
  }

  return normalizeIds(channelId, videoId, siteOwnerId);
}
