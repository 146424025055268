import React, { useState } from 'react';
import {
  Accordion,
  Button,
  ButtonPriority,
  InputDialog,
  TextButton,
} from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Filter as FilterIcon } from '@wix/wix-ui-icons-common/on-stage';
import {
  FilterOption,
  FilterTypes,
  FilterViewModel,
} from '../../../ViewModel/filterViewModel/filterViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { getSelectedOptions, optionsHaveChildren } from './utils';
import { FiltersDataHooks } from './dataHooks.const';
import { classes, st } from './Filters.st.css';
import { FilterContent } from './Filters';

type MobileFiltersProps = {
  filterViewModels: FilterViewModel[];
};

export function MobileFilters({ filterViewModels }: MobileFiltersProps) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { onFilterChanged, onElementClicked } = useCalendarActions();

  const [clonedViewModels, setClonedViewModels] = useState<FilterViewModel[]>(
    [],
  );
  const selectedOptionsCount = filterViewModels.reduce(
    (acc, options) => acc + getSelectedOptions(options).length,
    0,
  );

  const shouldDisableClearButton =
    isDialogOpen &&
    selectedOptionsCount === 0 &&
    clonedViewModels.every(
      (viewModel) => getSelectedOptions(viewModel).length === 0,
    );

  function openDialog() {
    onElementClicked(
      WidgetComponents.FILTER,
      WidgetElements.OPEN_FILTERS,
      getModalFilters(filterViewModels),
    );
    setClonedViewModels(
      filterViewModels.map((filterViewModel) => ({
        ...filterViewModel,
        options: filterViewModel.options.map((option) => ({
          ...option,
          children: option.children?.map((child) => ({ ...child })),
        })),
      })),
    );
    setIsDialogOpen(true);
  }

  function closeDialog() {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.CLOSE_FILTERS);
    setIsDialogOpen(false);
  }

  function getModalFilters(viewModels: FilterViewModel[]) {
    const modalFilters: Partial<Record<FilterTypes, string[]>> = {};
    for (const viewModel of viewModels) {
      modalFilters[viewModel.id] = getSelectedOptions(viewModel).map(
        ({ value }) => value,
      );
    }
    return modalFilters;
  }

  function applyFilters() {
    const modalFilters = getModalFilters(clonedViewModels);
    onElementClicked(
      WidgetComponents.FILTER,
      WidgetElements.APPLY,
      modalFilters,
    );
    onFilterChanged(modalFilters);
    setIsDialogOpen(false);
  }

  function clearFilters() {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.CLEAR);
    onFilterChanged(undefined);
    setIsDialogOpen(false);
  }

  function toggleOptionHandler(id: FilterTypes) {
    return (optionToToggle: FilterOption) => {
      optionToToggle.selected = !optionToToggle.selected;
      const updatedViewModels = clonedViewModels.map((viewModel) => {
        if (viewModel.id === id) {
          if (optionToToggle.children) {
            optionToToggle.children.forEach(
              (child) => (child.selected = optionToToggle.selected),
            );
            optionToToggle.indeterminate = false;
          } else if (optionsHaveChildren(viewModel.options)) {
            const parentOption = viewModel.options.find((option) =>
              option.children?.includes(optionToToggle),
            )!;
            parentOption.selected = parentOption.children!.every(
              (child) => child.selected,
            );
            parentOption.indeterminate =
              !parentOption.selected &&
              parentOption.children!.some((child) => child.selected);
          }
        }
        return viewModel;
      });
      onElementClicked(
        WidgetComponents.FILTER,
        WidgetElements.CHECKBOX,
        getModalFilters(clonedViewModels),
      );
      setClonedViewModels(updatedViewModels);
    };
  }

  const getItemLabel = (label: string, selectedCount: number) =>
    selectedCount === 0
      ? label
      : t('filters.mobile.item.label.with-selection', { label, selectedCount });

  return (
    <>
      <TextButton
        prefixIcon={<FilterIcon className={classes.filterModalCTASuffix} />}
        className={st(classes.filterModalCTA)}
        data-hook={FiltersDataHooks.FILTER_MODAL_CTA}
        onClick={openDialog}
      >
        {selectedOptionsCount
          ? t('filters.mobile.cta.with-selection', { selectedOptionsCount })
          : t('filters.mobile.cta')}
      </TextButton>
      <InputDialog
        title={t('filters.mobile.modal.title')}
        data-hook={FiltersDataHooks.FILTER_DIALOG}
        className={st(classes.dialog, { open: isDialogOpen })}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        fullscreen
        customFooter={
          <div className={classes.dialogFooter}>
            <Button
              upgrade
              fullWidth
              priority={ButtonPriority.basicSecondary}
              className={classes.secondaryDialogButton}
              data-hook={FiltersDataHooks.CLEAR_DIALOG_BUTTON}
              onClick={clearFilters}
              disabled={shouldDisableClearButton}
            >
              {t('filters.mobile.modal.secondary.cta')}
            </Button>
            <Button
              upgrade
              fullWidth
              className={classes.primaryDialogButton}
              data-hook={FiltersDataHooks.APPLY_DIALOG_BUTTON}
              onClick={applyFilters}
            >
              {t('filters.mobile.modal.primary.cta')}
            </Button>
          </div>
        }
      >
        <Accordion
          multiple
          data-hook={FiltersDataHooks.FILTERS_ACCORDION}
          className={classes.accordion}
          initiallyExpanded={filterViewModels.map(({ id }) => id)}
          onItemClick={(filterType) =>
            onElementClicked(
              WidgetComponents.FILTER,
              WidgetElements.FILTERS_ACCORDION_ITEM,
              { filterType },
            )
          }
        >
          {clonedViewModels.map((viewModel) => {
            const { id, label, options, note } = viewModel;
            return (
              <Accordion.Item
                id={id}
                key={id}
                title={getItemLabel(
                  label,
                  getSelectedOptions(viewModel).length,
                )}
              >
                <FilterContent
                  options={options}
                  note={note}
                  toggleOption={toggleOptionHandler(id)}
                />
              </Accordion.Item>
            );
          })}
        </Accordion>
      </InputDialog>
    </>
  );
}
