import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isSocialShareVisible} from '../../../selectors/settings'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {isEventDetailsMobileUpdateEnabled} from '../../../../../../commons/selectors/experiments'
import {SocialShareWithTitleOwnProps, SocialShareWithTitleRuntimeProps} from './interfaces'
import {SocialShareWithTitle as Presentation} from './with-title'

const mapRuntime = ({state}: DetailsPageAppProps): SocialShareWithTitleRuntimeProps => {
  const componentSettings = state.component.settings
  return {
    componentSettings,
    isVisible: isSocialShareVisible(componentSettings),
    mobile: isMobile(state),
    updatedInterfaceEnabled: isEventDetailsMobileUpdateEnabled(state),
  }
}

export const SocialShareWithTitle = connect<SocialShareWithTitleOwnProps, SocialShareWithTitleRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
