import { EditorSDK } from '@wix/platform-editor-sdk';
import { getIsResponsiveEditor } from '../../services/applicationState';
import { openAddTabsPanel } from '../../wrappers/panels';
import { MembersAreaPublicApi } from '../types';
import { navigateToMembersAreaPage } from './services/navigation';
import { getProfileType, setProfileType } from './services/blog-writer';
import { modifyPages, modifyPagesAndOpenManagePages } from './services/general-settings';
import {
  deleteVerticalApps,
  getDefinitions,
  getRegisteredApps,
  installApps,
  installRegisteredApps,
  registerMembersAreaApps,
} from './integration';
import { ReferralInfo } from '../../../types/bi';
import { getIsMembersAreaV2Context } from '../../services/context';
import { monitoredTransactionFactory } from '../../../utils/monitoring';

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  return {
    getRegisteredApps: () => {
      return getRegisteredApps(editorSDK);
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return monitoredTransaction('editorAPI.v2.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId);
      return monitoredTransaction('editorAPI.v2.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const action = async () => {
        const definitions = await getDefinitions(editorSDK, applications);
        return installApps(editorSDK, definitions);
      };

      return monitoredTransaction('editorAPI.v2.addApplications', action);
    },
    handleVerticalDeletion: (verticalAppDefId) => {
      const action = async () => {
        await navigateToMembersAreaPage(editorSDK);
        return deleteVerticalApps(editorSDK, verticalAppDefId);
      };
      return monitoredTransaction('editorAPI.v2.handleVerticalDeletion', action);
    },
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (profileType) => {
      return monitoredTransaction('editorAPI.v2.setProfileType', () => setProfileType(editorSDK, profileType));
    },
    modifyPages: (options) => {
      return monitoredTransaction('editorAPI.v2.modifyPages', () => modifyPages(editorSDK, options));
    },
    modifyPagesAndOpenManagePages: (options, referralInfo) => {
      return monitoredTransaction('editorAPI.v2.modifyPagesAndOpenManagePages', () =>
        modifyPagesAndOpenManagePages(editorSDK, options, referralInfo),
      );
    },
    refreshPageState: () => {
      return editorSDK.application.reloadManifest();
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(getIsResponsiveEditor());
    },
    openAddTabsPanel: (referralInfo?: ReferralInfo) =>
      openAddTabsPanel({ editorSDK, eventPayload: { pageRef: undefined, referralInfo } }),
    getIsMembersAreaV2Context: () => getIsMembersAreaV2Context(editorSDK),
    openGeneralSettingsPanel: async () => {
      throw new Error('[MAV2] PublicAPI.openGeneralSettingsPanel is not supported');
    },
    getGeneralSettingsPanelData: () => {
      throw new Error('[MAV2] PublicAPI.getGeneralSettingsPanelData is not supported');
    },
    getMemberPrivacySettings: () => {
      throw new Error('[MAV2] PublicAPI.getMemberPrivacySettings is not supported');
    },
    getMembersPageRef: (page) => {
      throw new Error('[MAV2] PublicAPI.getMembersPageRef is not implemented');
    },
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      throw new Error('[MAV2] PublicAPI.removeMembersAreaPage is not implemented');
    },
    setHorizontalLayout: (pwHeight) => {
      throw new Error('[MAV2] PublicAPI.setHorizontalLayout is not implemented');
    },
    setSidebarLayout: () => {
      throw new Error('[MAV2] PublicAPI.setSidebarLayout is not implemented');
    },
    addCustomPage: (isPrivate) => {
      throw new Error('[MAV2] PublicAPI.addCustomPage is not implemented');
    },
    refreshMembersAreaApps: () => {
      throw new Error('[MAV2] PublicAPI.refreshMembersAreaApps is not implemented');
    },
    setProfileWidgetHeight: (height: number) => {
      throw new Error('[MAV2] PublicAPI.setProfileWidgetHeight is not implemented');
    },
    /** @deprecated */
    refreshRouters: () => {
      throw new Error('[MAV2] PublicAPI.refreshRouters is not implemented');
    },
    /** @deprecated */
    registerAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.registerAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    getAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.getAdditionalWidgets is not implemented');
    },
    /** @deprecated */
    installAdditionalWidgets: () => {
      throw new Error('[MAV2] PublicAPI.installAdditionalWidgets is not implemented');
    },
  };
};
