export enum GeneralSettingsAction {
  ShowPublicOn = 'show_public_on',
  ShowPublicOff = 'show_public_off',
  Profile = 'profile',
  ProfileAll = 'profile_all',
  ProfileBlogWriters = 'profile_blog_writers',
  Followers = 'followers',
  Members = 'members',
  BackToOverviewCTA = 'back_to_overview_cta',
  FindMorePagesCTA = 'find_more_pages_cta',
}

export enum Origin {
  Editor = 'editor',
}

export enum Context {
  ManageMemberPages = 'manage_member_pages',
  AddTabs = 'add_tabs',
}

export enum ReferralInfo {
  ManageMemberPages = 'manage_member_pages',
  ActionsMenu = 'actions_menu',
  EditorProfileSettings = 'editor_profile_settings',
  ProfileManageMemberPages = 'editor_profile_manage_member_pages',
  GeneralSettingsPanel = 'general-settings-panel',
}

export type BiData = { origin?: string };
