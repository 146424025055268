import {FedopsInteractions, FORMS_CHECKOUT_NAMESPACE, FORMS_TEMPLATE_IDS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {FormsStoreProps} from '../../types/app.types';
import {FormKind, initFormController} from '@wix/form-viewer/controller';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';

export type FormsStoreConfig = {
  flowAPI: ControllerFlowAPI;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  biService: BIService;
  updateFormsLoaded: () => void;
  onLoadFailure: () => Promise<void>;
};

export class FormsStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly biService: BIService;
  private areFormsLoaded!: boolean;
  private readonly updateFormsLoaded: () => void;
  private readonly onLoadFailure: () => Promise<void>;

  constructor({
    flowAPI,
    navigationService,
    checkoutService,
    biService,
    updateFormsLoaded,
    onLoadFailure,
  }: FormsStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.navigationService = navigationService;
    this.onLoadFailure = onLoadFailure;
    this.updateFormsLoaded = updateFormsLoaded;
    this.areFormsLoaded = false;

    if (!this.navigationService.isFastFlow) {
      void this.initForms();
    }
  }

  private async initForms() {
    const requestedTemplates = [
      FORMS_TEMPLATE_IDS.EMAIL,
      FORMS_TEMPLATE_IDS.CONTACT,
      FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
      FORMS_TEMPLATE_IDS.ADDRESS,
      FORMS_TEMPLATE_IDS.VAT_ID,
    ];
    try {
      this.flowAPI.fedops.interactionStarted(FedopsInteractions.InitFormsInteraction);
      await initFormController(this.flowAPI, {
        formId: requestedTemplates,
        formKind: FormKind.EXTENSION,
        namespace: FORMS_CHECKOUT_NAMESPACE,
        enableMultilineAddress: true,
      });
      this.flowAPI.fedops.interactionEnded(FedopsInteractions.InitFormsInteraction);
    } catch (error) {
      this.biService.checkoutIntegratingFormsFailure(requestedTemplates, error, this.checkoutService.checkout);
      console.error('initFormController failed', error);
      await this.onLoadFailure();
      return;
    }
    this.areFormsLoaded = true;
    this.updateFormsLoaded();
  }

  public toProps(): FormsStoreProps {
    return {
      areFormsLoaded: this.areFormsLoaded,
    };
  }
}
