import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function shouldSetResponsiveLayoutForApps() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.setResponsiveLayoutForApps') === 'true';
}

async function shouldFixCorruptedRouterPatterns() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.FixCorruptedRouterPatterns') === 'true';
}

async function shouldDeleteMobileComponents() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.handleMobileComponentsDeletion') === 'true';
}

async function shouldFixLoginBarResponsiveLayout() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.fixLoginBarResponsiveLayout') === 'true';
}

async function shouldDisableParallelAppInstall() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableParallelAppInstall') === 'true';
}

async function shouldEnableSignUpPrivacyNoteType() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.ident.EnableSignUpPrivacyNoteType') === 'true';
}

async function shouldUseMembersAreaOnBlocksInstallFlow() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.profilePageBoBInstallation') === 'true';
}

async function shouldEnableTimeoutLogs() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.enableTimeoutLogs') === 'true';
}

async function shouldEnablePlatformizedUninstallEditorX() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EnablePlatformizedUninstallEditorX') === 'true';
}

async function shouldAllowDeletionOfBrokenApp() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AllowDeletionOfBrokenApp') === 'true';
}

async function shouldNotAllowStartAfterStop() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.NoStartAfterStop') === 'true';
}

async function shouldEnableSeoPanelChanges() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.seoPanelChanges') === 'true';
}

async function shouldUseNewAppInstallCheck() {
  try {
    await maybeConductExperiments();
  } catch {}

  return conductedExperiments.get('specs.membersArea.UseNewAppInstallCheck') === 'true';
}

export {
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  shouldSetResponsiveLayoutForApps,
  shouldFixCorruptedRouterPatterns,
  shouldDeleteMobileComponents,
  shouldFixLoginBarResponsiveLayout,
  shouldDisableParallelAppInstall,
  shouldEnableSignUpPrivacyNoteType,
  shouldUseMembersAreaOnBlocksInstallFlow,
  shouldEnableTimeoutLogs,
  shouldEnablePlatformizedUninstallEditorX,
  shouldAllowDeletionOfBrokenApp,
  shouldNotAllowStartAfterStop,
  shouldEnableSeoPanelChanges,
  shouldUseNewAppInstallCheck,
};
