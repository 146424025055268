import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  getTrackingInfoForBookButtonClick,
  getTrackingInfoForNavigateToServicePageClick,
} from '@wix/bookings-analytics-adapter';
import {
  CatalogServiceDto,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';
import {
  bookingsBookItClick,
  bookingsCantBookGroupsMessage,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { Navigation } from '../../utils/navigation/navigation';
import { ServiceIntent } from '../../utils/navigation/navigation.const';
import { BookingsApi } from '../../api/BookingsApi';
import { WidgetConfig } from '../../../legacy/types';
import {
  ActionNames,
  BiServiceType,
  ReferralInfo,
} from '../../utils/bi/consts';
import { WidgetViewModel } from '../../viewModel/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  FilterServicesByOptions,
} from '../../types/types';
import { ALL_SERVICES } from '../../consts';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';

export type NavigationAction = (
  service: CatalogServiceDto,
  referralInfo: ReferralInfo,
) => void;

export type HandelNavigationAction = ({
  service,
  intent,
  referralInfo,
  locationId,
}: {
  service: CatalogServiceDto;
  intent: ServiceIntent;
  referralInfo: ReferralInfo;
  locationId?: string;
}) => void;

export const createHandelNavigationAction = ({
  widgetViewModel,
  wixSdkAdapter,
  bookingsApi,
  flowAPI,
  config,
  setProps,
  isAnywhereFlow,
}: {
  widgetViewModel: WidgetViewModel;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsApi: BookingsApi;
  flowAPI: ControllerFlowAPI;
  config: WidgetConfig;
  setProps: Function;
  isAnywhereFlow?: boolean;
}): HandelNavigationAction => {
  return ({
    service,
    intent,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    intent: ServiceIntent;
    referralInfo: ReferralInfo;
  }) => {
    const { controllerConfig, bi, experiments } = flowAPI;
    const { compId } = controllerConfig;
    const bookingsValidations = new BookingValidations(
      wixSdkAdapter,
      bookingsApi,
      flowAPI.experiments.enabled('specs.bookings.V1Shutdown'),
    );
    const navigation = new Navigation(wixSdkAdapter, compId, experiments);
    const activeFeatures = JSON.parse(config.activeFeatures);

    const isServiceListBookButtonLoaderEnabled = experiments.enabled(
      'specs.bookings.serviceListBookButtonLoader',
    );
    const shouldShowPremiumModalInDayfulFlow =
      !experiments.enabled('specs.bookings.DayfulMovePremiumBlockModal') &&
      isAnywhereFlow;

    if (isServiceListBookButtonLoaderEnabled) {
      widgetViewModel.navigatingToService = service.id;
      setProps({
        widgetViewModel: {
          ...widgetViewModel,
        },
      });
    }
    bookingsValidations
      .shouldNavigate(
        service,
        activeFeatures,
        intent,
        shouldShowPremiumModalInDayfulFlow,
      )
      .then(async ({ canBook, reason }) => {
        if (canBook) {
          const data = {
            service,
            businessName: config.businessInfo.name!,
          };
          const trackingInfo =
            intent === ServiceIntent.BOOK_OFFERING
              ? getTrackingInfoForBookButtonClick(data)
              : getTrackingInfoForNavigateToServicePageClick(data);
          wixSdkAdapter.trackAnalytics(trackingInfo);

          bi?.report(
            bookingsBookItClick({
              service_id: service.id,
              type: BiServiceType[service.type],
              isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
              referralInfo,
              actionName: ActionNames[intent],
            }),
          );

          const queryParam = getResourceQueryParam(wixSdkAdapter);
          const locationId = getLocationId(flowAPI, widgetViewModel);

          await navigation.navigateToApp({
            service,
            intent,
            location: locationId,
            ...queryParam,
          });
        } else {
          if (reason) {
            try {
              if (reason.premiumError) {
                await bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt();
              } else if (reason.pricingPlanError) {
                await bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
                  getNotifyPricingPlanRequest(service, reason),
                );
              }
            } catch (error) {
              console.error(error);
            }
          }

          bi?.report(
            bookingsCantBookGroupsMessage({
              referralInfo: ReferralInfo.WIDGET,
              failReason: reason?.siteNotEcomError
                ? 'non ecom site'
                : undefined,
            }),
          );

          widgetViewModel.showDialog = true;
        }
      })
      .catch(console.error)
      .finally(() => {
        if (isServiceListBookButtonLoaderEnabled) {
          widgetViewModel.navigatingToService = '';
        }
        setProps({
          widgetViewModel: {
            ...widgetViewModel,
          },
        });
      });
  };
};

const getLocationId = (
  flowAPI: ControllerFlowAPI,
  widgetViewModel: WidgetViewModel,
) => {
  const { settings } = flowAPI;
  let locationId = '';
  const isListFilterVisible = settings.get(settingsParams.isListFilterVisible);
  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  const displayServicesBy = settings.get(settingsParams.displayServicesBy);
  const selectedLocations = settings.get(settingsParams.selectedLocations);
  const isOnlyOneLocationSelected =
    displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS &&
    selectedLocations?.length === 1;

  if (
    isListFilterVisible &&
    filterServicesBy === FilterServicesByOptions.LOCATIONS
  ) {
    const selectedFilterOption = widgetViewModel.filterOptions.find(
      (option) => option.isSelected,
    )!.id;
    locationId =
      selectedFilterOption !== ALL_SERVICES &&
      selectedFilterOption !== ReservedLocationIds.OTHER_LOCATIONS
        ? selectedFilterOption
        : '';
  } else if (isOnlyOneLocationSelected) {
    locationId = selectedLocations[0];
  }
  return locationId;
};
