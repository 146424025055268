import React from 'react';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import {FilterType} from '../../../../types/galleryTypes';
import {Tag, TagSize, TextButton} from 'wix-ui-tpa';
import {classes} from './AppliedFilters.st.css';
import {getActiveFilterOptions} from '../filterUtils';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

type AppliedFiltersProps = IGalleryGlobalProps & IProvidedTranslationProps;

export enum DataHook {
  AppliedFilterTag = 'applied-filter-tag',
  ClearFilters = 'applied-filters-clear',
}

export const AppliedFilters: React.FunctionComponent<AppliedFiltersProps> = withGlobals(
  withTranslations()((props: AppliedFiltersProps) => {
    const {filterModels, filterProducts, shouldShowMobile, clearFilters} = props.globals;
    const {t} = props;

    const getActiveFilterOptionTagText = (activeOption) => {
      switch (activeOption.filterType) {
        case FilterType.COLLECTION:
        case FilterType.COLOR_OPTION:
        case FilterType.CUSTOM_COLLECTION:
        case FilterType.LIST_OPTION:
          return `${activeOption.value}`;
        case FilterType.PRICE:
          return t('appliedFiltersPriceTag', {
            minPrice: activeOption.minValue,
            maxPrice: activeOption.maxValue,
          });
      }
    };

    const appliedFilters = getActiveFilterOptions(filterModels, filterProducts);

    return (
      <>
        {appliedFilters.map((activeOption, index) => {
          const activeFilterOptionTagText = getActiveFilterOptionTagText(activeOption);

          return (
            <Tag
              key={`${activeOption.filterId}-${activeOption.value}`}
              size={shouldShowMobile ? TagSize.small : TagSize.medium}
              isRemovable
              onRemove={() => activeOption.removeFunc()}
              className={classes.tagRoot}
              data-hook={`${DataHook.AppliedFilterTag}-${index}`}
              removeButtonAriaLabel={`${activeFilterOptionTagText}, ${t('appliedFilterClearSR')}`}>
              {activeFilterOptionTagText}
            </Tag>
          );
        })}
        {appliedFilters.length && (
          <TextButton
            onClick={() => clearFilters()}
            className={classes.textButtonRoot}
            data-hook={DataHook.ClearFilters}>
            {t('appliedFiltersClearAllButton')}
          </TextButton>
        )}
      </>
    );
  })
);
