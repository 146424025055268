import { EditorSDK } from '@wix/platform-editor-sdk';

import { Context, Origin, ReferralInfo } from '../../types/bi';
import { createBIService } from '../../utils/bi';
import { getIsResponsiveEditor } from '../services/applicationState';
import { buildPanelUrl } from '../services/panel-url-builder';
import { APP_TOKEN } from '../constants';

interface OpenModalPanelProps<T = any> {
  editorSDK: EditorSDK;
  eventPayload?: T;
}

export interface OpenManagePanelPayload {
  pageRef?: unknown;
  referralInfo?: ReferralInfo;
}

export type OpenAddTabsPanelPayload = OpenManagePanelPayload;

export async function openManagePagesPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenManagePanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.ManageMemberPages,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'managePages.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openAddTabsPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenAddTabsPanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({
    origin: Origin.Editor,
    referralInfo: eventPayload?.referralInfo,
    context: Context.AddTabs,
  });

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'addTabs.html'),
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openGeneralSettingsPanel({ editorSDK, eventPayload }: OpenModalPanelProps) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const query = isResponsiveEditor ? '?origin=editor_x' : '';

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: await buildPanelUrl(editorSDK, 'generalSettings.html', query),
    width: 876,
    height: 631,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export function openMemberPrivacySettingsDashboard(editorSDK: EditorSDK) {
  return editorSDK.editor.openDashboardPanel(APP_TOKEN, {
    url: '/member-privacy-settings/profile-privacy?referralInfo=editor_actions_menu',
    closeOtherPanels: false,
  });
}
