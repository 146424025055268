import { EditorSDK } from '@wix/platform-editor-sdk';

import { getGlobalMembersAreaController } from '../wrappers/controllers';
import { shouldUseMembersAreaOnBlocksInstallFlow } from '../../utils/experiments';

export const getIsMembersAreaV2Context = async (editorSDK: EditorSDK, firstInstall: boolean = false) => {
  if (firstInstall) {
    return shouldUseMembersAreaOnBlocksInstallFlow();
  }

  try {
    const controllerConfig = await getGlobalMembersAreaController(editorSDK);

    return !!controllerConfig?.isMembersAreaV2;
  } catch (e) {
    return false;
  }
};
