import { useCallback, useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ISettingsParam } from '@wix/tpa-settings';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { useWixSdk } from '../../../Settings/utils/flow';
import {
  normalizeIdsFromFonts,
  normalizeIdsFromSettings,
} from './normalize-ids';
import { useFedopsInteractions } from '../fedops/interactions';
import { useSelector } from 'react-redux';
import { getSiteOwnerId } from '../redux/hydrated-data/hydrated-data';

function useMigrateIdsToSettingsParams(
  fontsChannelId: string,
  fontsVideoId: string,
) {
  const { Wix } = useWixSdk();
  const { isEditor } = useEnvironment();
  const settings = useSettings();
  const fedopsInteractions = useFedopsInteractions();

  const useIdsFromSettings = settings.get(settingsParams.useIdsFromSettings);

  const setData = useCallback(
    (param: ISettingsParam, value: any) =>
      new Promise((resolve, reject) => {
        if (Wix) {
          // use Wix.Data directly to avoid CantModifyParamError when update settings from the widget 😁
          // https://github.com/wix-private/tpa-settings/blob/fe32e60e08ec034e13e60cceb182dc95544fa598/packages/tpa-settings/src/contexts/SettingsContext/SettingsProvider.tsx#L182
          Wix.Data.Public.set(
            param.key,
            value,
            {
              scope: Wix.Data.SCOPE.COMPONENT,
            },
            () => resolve(null),
            () => reject(new Error(`Failed to set ${param.key}`)),
          );
        }
      }),
    [Wix],
  );

  const migrateIdsToSettingsParams = useCallback(async () => {
    try {
      fedopsInteractions.start('MIGRATE_IDS_TO_SETTINGS_PARAMS');
      await setData(settingsParams.channelId, fontsChannelId);
      await setData(settingsParams.videoId, fontsVideoId);
      await setData(settingsParams.useIdsFromSettings, true);
      fedopsInteractions.end('MIGRATE_IDS_TO_SETTINGS_PARAMS');
    } catch (e) {}
  }, [setData, fontsChannelId, fontsVideoId, fedopsInteractions]);

  useEffect(() => {
    if (isEditor && !useIdsFromSettings) {
      migrateIdsToSettingsParams();
    }
  }, [isEditor, migrateIdsToSettingsParams, useIdsFromSettings]);
}

export function useIds() {
  const settings = useSettings();
  const styles = useStyles();
  const siteOwnerId = useSelector(getSiteOwnerId);
  const useIdsFromSettings = settings.get(settingsParams.useIdsFromSettings);
  const idsFromFonts = normalizeIdsFromFonts(
    styles.get(stylesParams.channelId),
    styles.get(stylesParams.videoId),
    siteOwnerId,
  );
  const idsFromSettings = normalizeIdsFromSettings(
    settings.get(settingsParams.channelId),
    settings.get(settingsParams.videoId),
    siteOwnerId,
  );

  useMigrateIdsToSettingsParams(idsFromFonts.channelId, idsFromFonts.videoId);

  if (useIdsFromSettings) {
    return idsFromSettings;
  }

  return idsFromFonts;
}
