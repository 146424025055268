import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {useEffect, useState} from 'react';
import {StatesButtonStates} from 'wix-ui-tpa';

export function useContinueButtonState() {
  const {
    checkoutStore: {updateCheckoutError},
    formsStore: {areFormsLoaded},
  } = useControllerProps();

  const {editMode, areMemberDetailsValid} = useMemberDetailsData();

  const [continueButtonState, setContinueButtonState] = useState(StatesButtonStates.IDLE);

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(!areFormsLoaded);

  useEffect(() => {
    if (updateCheckoutError) {
      setContinueButtonState(StatesButtonStates.IDLE);
      setIsContinueButtonDisabled(false);
    }
  }, [updateCheckoutError]);

  useEffect(() => {
    const disableContinueButton = !areFormsLoaded || (!areMemberDetailsValid && !editMode);
    setIsContinueButtonDisabled(disableContinueButton);
  }, [areFormsLoaded, areMemberDetailsValid, editMode]);

  return {continueButtonState, isContinueButtonDisabled, setContinueButtonState, setIsContinueButtonDisabled};
}
